@import 'theme.css';

.Card {
    background-color: var(--card-color);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color);
}

.Card-textbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.Card-title {
    font-family: var(--title-font);
    font-size: xx-large;
}

.Card-text {
    font-family: var(--body-font);
    font-size: x-large;
}

.Card-textbox::-webkit-scrollbar {
    width: 1em;
}

.Card-textbox::-webkit-scrollbar-track {
    background: var(--card-color);
    border: solid 10px transparent;
}

.Card-textbox::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
}
