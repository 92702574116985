:root {
    /* UI elements */
    --background-color: #28342a;
    --card-color: #1a1817;
    --scrollbar-color: #4d4208;

    /* Text */
    --text-color: #e8c810;
    --shadow-color: #a18b0e;
    --disabled-color: #a69951;

    --title-font: 'Irish Grover';
    --body-font: 'Libre Baskerville';
}

.svg-in-text-color {
    /* Computed from text-color using https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(89%) sepia(25%) saturate(7488%) hue-rotate(8deg) brightness(98%) contrast(88%);
}
