@import 'theme.css';

.Homepage {
    text-align: center;
}

.Homepage-header {
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--text-color);
}

.Homepage-title {
    font-family: var(--title-font);
    font-size: 128px;
    text-shadow: 4px 4px 4px var(--shadow-color);
}

.Homepage-link {
    font-family: var(--title-font);
    font-size: 56px;
    cursor: pointer;
}

.Homepage-link:hover {
    text-shadow: 3px 3px 3px var(--shadow-color);
}

.Homepage-prefs-link {
    cursor: pointer;
    color: var(--text-color);
    font-size: 40px;
    position: absolute;
    top: 0px;
    right: 10px;
}

.Homepage-prefs-link:hover {
    text-shadow: 3px 3px 3px var(--shadow-color);
}
