@import 'theme.css';

.Session {
    background-color: var(--background-color);
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
}

.Session-control-box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.Session-control {
    cursor: pointer;
    color: var(--text-color);
}

.Session-control-disabled {
    cursor: pointer;
    color: var(--disabled-color);
}

.Session-control:hover {
    text-shadow: 3px 3px 3px var(--shadow-color);
}

.Session-history-indicator {
    cursor: pointer;
    color: var(--text-color)
}

.Session-history-indicator-hidden {
    cursor: pointer;
    color: transparent;
}

.Session-prefs-link {
    cursor: pointer;
    color: var(--text-color);
    font-size: 40px;
    position: absolute;
    top: 0px;
    right: 10px;
}

.Session-prefs-link:hover {
    text-shadow: 3px 3px 3px var(--shadow-color);
}
